// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contatti-js": () => import("/opt/build/repo/src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-grazie-js": () => import("/opt/build/repo/src/pages/grazie.js" /* webpackChunkName: "component---src-pages-grazie-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pubblicazioni-js": () => import("/opt/build/repo/src/pages/pubblicazioni.js" /* webpackChunkName: "component---src-pages-pubblicazioni-js" */),
  "component---src-pages-settori-js": () => import("/opt/build/repo/src/pages/settori.js" /* webpackChunkName: "component---src-pages-settori-js" */),
  "component---src-pages-studio-js": () => import("/opt/build/repo/src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-team-js": () => import("/opt/build/repo/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

