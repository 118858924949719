/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  return [0, 0]
}

const parallax = () => {
    window.addEventListener('scroll', function(e) {
        const a = document.querySelector('.home .header-image');
        const width = window.innerWidth
        var offset = 1
        var speed = 1.4
        if (width < 1500) {
            speed = 0.3
        }
        if (width < 992) {
            return false
        }
        const scrollTop = ((window.scrollY / offset) * speed) * -1
        a.style.backgroundPosition = "center " + scrollTop + 'px'
    })

}

exports.onInitialClientRender = () => {
    console.log("ReactDOM.render has executed")
    if (window.location.pathname == '/') parallax()
    window.onscroll = function() { scrollFunction() };
    switch (localStorage.lang) {
        case 'it':
        document.querySelector('#changeLanguage').selectedIndex = 0
            break;
            case 'en':
        document.querySelector('#changeLanguage').selectedIndex = 1
            break;
            case 'de':
        document.querySelector('#changeLanguage').selectedIndex = 2
            break;
        default:
            break;
    }
    activeLink()
}

exports.onClientEntry = () => {
  window.addEventListener('load', (event) => {
    var userAgent = window.navigator.userAgent
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      document.body.classList += ' safari-mobile'
    }
    if (document.querySelector('.convegni .tab-nav .active a') !== null) {
      document.querySelector('.convegni .tab-nav .active a').click()
    }
  })
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  window.addEventListener('load', (event) => {
    if (window.location.pathname === '/settori/' && window.location.href.includes("#")) {
      let elementPositions = document.getElementById(window.location.href.split('#')[1]).getBoundingClientRect()
      setTimeout(function() {
        window.scrollTo(0, elementPositions.top)
      }, 500)
    }
  })
}

function activeLink(){
    let path = window.location.pathname
    if (path.length > 2) {
        path = path.replace(new RegExp('/', 'gi'), '')
        if(window.location.pathname.indexOf(path) != -1){
            document.getElementById(path).style.opacity = '1'
        }
    }
}


function scrollFunction() {
    if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
        document.getElementById("myBtn").style.right = "30px";
    } else {
        document.getElementById("myBtn").style.right = "-90px";
    }
}







function initGTM() {
  if (window.isGTMLoaded) {
    return false;
  }

  window.isGTMLoaded = true;

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-YY556SKEW4`;

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', `G-YY556SKEW4`);
  };

  document.head.appendChild(script);
}

function loadGTM(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
}

exports.onClientEntry = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== 'loading') {
      setTimeout(initGTM, 3500);
    }
  };

  document.addEventListener('scroll', loadGTM);
  document.addEventListener('mousemove', loadGTM);
  document.addEventListener('touchstart', loadGTM);
};


